/* custom css */
.container {
  max-width: 1280px;
}

.conainer-verification {
  margin: 0px;
  margin-top: 5%;
  text-align: center;
}
.wrapper {
  max-width: 1140px;
}
/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Inter', sans-serif;
  min-width: 320px;
}

/* colors */

:root {
  --color-primary-dark: #3b2f75;
  --color-primary-light: #5723e9;
  --color-primary-orange: #ff9320;
  /* ... */
}

.border-orange {
  color: var(--color-primary-orange);
  border-color: var(--color-primary-orange);
  background: var(
    --gradients-orange-fill,
    linear-gradient(
      180deg,
      rgba(255, 147, 32, 0) 0%,
      rgba(255, 147, 32, 0.58) 100%
    )
  );
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
}

.filled-btn {
  color: var(--color-primary-light);
}
.backbtn {
  border: 2px solid var(--color-primary-light);
}
.blue-text {
  color: var(--color-primary-light);
}
.blue-btn {
  background: var(--color-primary-light);
  border-color: var(--color-primary-light);
}
.flex-box p {
  color: #5c5c5c;
}

.banner-sec {
  background: var(
    --gradients-bg-gradient,
    linear-gradient(21deg, #5723e8 0%, #030639 100%)
  );
}
.banner-sec::before {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: url(../public/images/banner-before.webp) 0 center no-repeat;
}

.tab-outer {
  box-shadow: 0px 5px 64px 0px rgba(59, 48, 117, 0.4);
  background: linear-gradient(200.66deg, #5723e8 24.35%, #3b3075 92.58%);
}
.tab-outer::before {
  position: absolute;
  left: 4px;
  top: 4px;
  content: '';
  height: calc(100% - 8px);
  width: calc(100% - 8px);
  background: #f5f5f5;
  border-radius: 16px;
}
.tab-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.tab-buttons button {
  display: flex;
  border-radius: 8px;
  opacity: 0.5;
  background: #fff;
  height: 48px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #5723e8;
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  border-radius: 8px;

  border: 2px solid transparent;
}
.tab-buttons button.active {
  border-radius: 8px;
  border: 2px solid #5723e8;
  opacity: 1;
  background: #fff;
}

.dark-bar {
  background: #1a1a1a;
}

.tab-content {
  max-width: 900px;
  margin: auto;
}
.text-4l {
  font-size: 40px;
}

.tab-content-inner li {
  position: relative;
  font-size: 16px;
  padding-left: 48px;
  margin-top: 32px;
  opacity: 0.699999988079071;
}
.tab-content-inner li::before {
  position: absolute;
  content: '';
  width: 32px;
  height: 32px;
  background: url(../public/images/check-list.svg) 0 0 no-repeat;
  left: 0;
  top: 0;
}

.card-inner {
  box-shadow: 0px 5px 64px 0px rgba(59, 48, 117, 0.4);
}
.blue-card {
  border-color: var(--color-primary-light);
  background: var(--color-primary-light);
}
.card-detail li {
  color: #fff;
  position: relative;
  padding-left: 24px;
  margin: 8px 0;
}
.card-detail li::before {
  position: absolute;
  left: 0;
  content: '';
  height: 12px;
  width: 12px;
  background: #fff;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}
.card-detail h4 {
  margin-bottom: 12px;
  margin-top: 20px;
}
.card-inner.bg-white {
  background: #fff;
}
.card-inner.bg-white h4 {
  color: var(--color-primary-light);
}
.card-inner.bg-white li {
  color: var(--color-primary-light);
}
.card-inner.bg-white li::before {
  background: var(--color-primary-light);
}
.products-sec {
  background: #f7f4fe;
}
.product-box {
  background: linear-gradient(52.93deg, #5723e8 12.66%, #030639 66.5%);
}

.card-icon {
  display: flex;
  width: 96px;
  height: 96px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
}
.card-orange {
  background: var(--color-primary-orange);
  border-color: var(--color-primary-orange);
}
.orange-text {
  color: var(--color-primary-orange);
}
.text-box-left p {
  color: #1a1a1a;
}
.Team-header p {
  color: #1a1a1a;
  opacity: 0.699999988079071;
  line-height: 1.4;
}
.team-img img {
  box-shadow: 0px 5px 32px 0px rgba(26, 26, 26, 0.2);
  border-radius: 8px;
  height: 260px;
  width: 260px;
  object-fit: cover;
}

.logoStore img {
  width: 150px;
  height: 50px;
}
.team-details p {
  opacity: 0.699999988079071;
}
.dark-btn {
  background: #1a1a1a;
  color: #fff;
  height: 60px;
  line-height: 56px;
}
.white-btn {
  background: #fff;
  color: #1a1a1a;
}
.gray-btn {
  background: rgba(255, 255, 255, 0.1);
  border: 0;
}
.test-slide {
  background: #1a1a1a;
  height: 100%;
  position: relative;
  padding-bottom: 77px;
}
.slider-outer .slick-track {
  display: flex !important;
}

.slider-outer .slick-slide {
  height: inherit !important;
}
.slider-outer .slick-slide div {
  height: 100%;
}
.testimonial-sec {
  background: rgba(255, 147, 32, 0.15);
}
.testimonial-sec .slick-arrow {
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 0;
}
.testimonial-sec .slick-arrow::before {
  display: none;
}
.testimonial-sec .slick-next {
  background-image: url(../public/images/arrow-next.webp);
  right: -60px;
}
.testimonial-sec .slick-prev {
  background-image: url(../public/images/arrow-back.webp);
  left: -60px;
}
.test-slide p {
  color: #fff;
  font-size: 18px;
  line-height: 1.4;
}
.test-slide h6 {
  font-weight: normal;
  opacity: 0.5;
  color: #fff;
  font-size: 18px;
  line-height: 1.4;
  position: absolute;
  left: 32px;
  bottom: 40px;
}

.test-slide .text-under-h6 {
  color: #fff;
  opacity: 0.8;
  font-size: 12px;
  line-height: 1.4;
  position: absolute;
  left: 45px;
  bottom: 8px;
}
.test-slide .santiago {
  color: #fff;
  opacity: 0.8;
  font-size: 12px;
  line-height: 1.4;
  position: absolute;
  left: 45px;
  bottom: 22px;
}

.how-box h3 {
  font-size: 26px;
}
.how-box {
  box-shadow: 0px 5px 64px 0px rgba(59, 48, 117, 0.4);
}
.how-box li {
  color: #1a1a1a;
  position: relative;
  padding-left: 24px;
  margin: 8px 0;
}
.how-box li::before {
  position: absolute;
  left: 0;
  content: '';
  height: 12px;
  width: 12px;
  background: var(--color-primary-light);
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}
/* .footer-top::after{
  content: "";
  height: calc(100% - 200px);
  width: 100%;
  background: linear-gradient(52.93deg, #2F2F2F 12.66%, #1A1A1A 66.5%);
  position: absolute;
  bottom: 0;
  z-index: -1;
} */

/* footer::before{
  content: "";
  position: absolute;
  left: 0;
  top: 0px;
  background: url(../public/images/footer-border.svg) 0 0 no-repeat;
  background-size: 100% auto;
height: 60px;
width: 100%;
z-index: 2;
} */
.footer-dark::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0px;
  background: #1a1a1a;
  background-size: 100% auto;
  height: 90px;
  width: 100%;
  z-index: -1;
}
.footer-contact {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.footer-top h4 {
  color: #fff;
  font-size: 26px;
  font-weight: 900;
  margin-bottom: 16px;
}
.footer-top li {
  color: #fff;
  opacity: 0.699999988079071;
  margin-bottom: 12px;
}
.social-logo li {
  opacity: 1;
}
.footer-bottom {
  background: #272727;
}
@media (max-width: 768px) {
  ul.footer-bottomlist {
    display: block
  }
}
.footer-bottomlist a {
  opacity: 0.699999988079071;
  color: #fff;
}

/* inner pages */
.navbar-default .globe-icon svg path {
  fill: #1a1a1a;
}

.navbar-default a svg,
.navbar-default a svg path {
  fill: #1a1a1a;
  outline: #1a1a1a;
}

/* gallery */

.trans-bg {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
}
.media-duration {
  left: 14px;
  top: 14px;
  font-size: 12px;
  padding: 4.5px 8px;
}
.media-lock {
  position: absolute;
  right: 14px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  top: 8px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-trans {
  position: absolute;
  left: 14px;
  border-radius: 8px;
  bottom: 14px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  padding: 10px 8px;
  width: calc(100% - 28px);
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}
.title-trans span {
  padding-left: 8px;
}
.main-gallery-page {
  background: #1a1a1a;
}
.gallery-thumb {
  margin-bottom: 12px;
}
.head-3 {
  font-size: 32px;
}
/* buttons */
.gallery-tab-buttons button {
  display: flex;
  height: 48px;
  padding: 0px 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  margin: 0 8px;
  cursor: pointer;
  color: #fff;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
}
.gallery-tab-buttons button.active {
  border-radius: 8px;
  background: var(--primary, #5723e8);
}

.video-action span {
  border-radius: 4px;
  background: #313131;
  height: 32px;
}
.consult-header {
  text-align: center;
  max-width: 760px;
  margin: auto;
}

.consult-header p {
  color: #1a1a1a;
}
.cons-box .free-item{
  color:#333;
  text-decoration: line-through;
}
.cons-box {
  background: #fff;
  box-shadow: 0px 5px 64px 0px rgba(59, 48, 117, 0.4);
}
.cons-box.disabled {
  opacity: .5
}
.cons-box.disabled button{
  pointer-events: none;
  background: #1a1a1a
}

.cons-img {
  position: relative;
}
.cons-img img {
  width: 64px;
}
.cons-img span {
  display: block;
  width: 32px;
  height: 32px;
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background: rgba(59, 48, 117, 1);
  border-radius: 50%;
  color: #fff;
  font-weight: 900;
  position: absolute;
  left: -8px;
  top: -4px;
}
.cons-detail h3 {
  font-size: 26px;
}
.cons-description {
  color: #1a1a1a;
  opacity: 0.7;
  font-size: 16px;
}
.link-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between; /* Positions links at opposite ends */
  align-items: center; /* Vertically center the links */
}

.read-more-link {
  display: block;
  text-align: right; /* Alinea el enlace a la derecha */
  color: var(
    --color-primary-dark
  ); /* Cambia el color a rojo (puedes usar tu color preferido) */
  cursor: pointer; /* Cambia el cursor a una mano al pasar sobre el enlace */
}
.consult-link {
  display: block;
  text-align: left; /* Alinea el enlace a la derecha */
  color: var(
    --color-primary-light
  ); /* Cambia el color a rojo (puedes usar tu color preferido) */
  cursor: pointer; /* Cambia el cursor a una mano al pasar sobre el enlace */
}

.consult-button {
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  margin-top: 5px; /* Ajusta la separación vertical según tus necesidades */
}

.cons-detail p {
  color: #1a1a1a;
  opacity: 0.7;
  font-size: 18px;
}

/* acount-pages */
.login-page {
  background: #1a1a1a;
  min-height: 100vh;
  position: relative;
}
.login-page::before {
  content: '';
  height: 2px;
  width: 100%;
  background: linear-gradient(
    90deg,
    #382f71 0%,
    #5027e1 19.27%,
    #a433e1 40.62%,
    #4fa1e2 59.9%,
    #e9ce58 79.69%,
    #f1983d 100%
  );
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
}
.login-image-right {
  width: 542px;
}
.login-image-right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.input-login {
  background: #393939 !important;
  appearance: none;
}
select.input-login {
  background: url(../public/images/select-drop-icon.svg) calc(100% - 10px)
    center no-repeat #5f5f5f !important;
  appearance: none;
}
.bottom-links {
  color: #494949;
}
.bottom-links a {
  color: #fff;
}
.footer-login {
  color: #fff;
  opacity: 0.4;
  font-size: 14px;
}
.login-logo img {
  width: 167px;
}
.additiona-info {
  max-width: 650px;
}
.yes-no-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.yes-no-btn label {
  width: calc(50% - 5px);
  display: block;
  height: 48px;
  position: relative;
}
.yes-no-btn label input[type='radio'] {
  appearance: none;
  position: absolute;
}

.yes-no-btn label span {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border-radius: 8px;
  background: #5f5f5f;
  display: block;
  text-align: center;
  line-height: 48px;
  color: #fff;
  cursor: pointer;
  font-weight: 900;
}
.yes-no-btn label input[type='radio']:checked + span {
  background: #fff;
  color: #1a1a1a;
}

.profile-right {
  width: calc(100% - 308px);
}
.profile-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  height: 100vh;
  position: sticky;
  top: 0;
}
.sidebar-menu li a {
  display: flex;
  color: #fff;
  opacity: 0.5;
  border-radius: 8px;
}
.sidebar-menu li a svg {
  margin-right: 10px;
}
.sidebar-menu li.active a svg,
.sidebar-menu li.active a svg * {
  fill: #000;
  stroke: #000;
}
.sidebar-menu li a:hover {
  opacity: 0.5;
  background: #3d3d3d;
}
.sidebar-menu li.active a {
  background: #fff;
  opacity: 1;
}
.sidebar-menu li.active a {
  color: #000;
  font-weight: 600;
}
.profile-bottom {
  height: 64px;
  border-radius: 32px;
  background: #313131;
}
.premium-text {
  background: linear-gradient(
    270deg,
    #f9f295 0%,
    rgba(224, 170, 62, 0.78) 21.87%,
    #faf398 67.71%,
    #b88a44 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.profile-dd-icon {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
}
.border-gradiant {
  height: 2px;
  width: 100%;
  background: linear-gradient(
    90deg,
    #382f71 0%,
    #5027e1 19.27%,
    #a433e1 40.62%,
    #4fa1e2 59.9%,
    #e9ce58 79.69%,
    #f1983d 100%
  );
}
.employee-sec {
  background: #1b1b1b;
}

.step-form-outer label > span > span{
  padding-left: 35px;
  text-align: left;
}
.step-form-outer label > span {
  background: #e8e8e8;
  border: 2px solid transparent;
  cursor: pointer;
}
.step-form-outer label > span::before {
  content: '';
  position: absolute;
  top:18px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 1px solid #5723e8;
  display: inline-block;
}
.step-form-outer  label > span::after {
  content: '';
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #5723e8;
  display: block;
  position: absolute;
  left: 38px;
  top: 30px;
  opacity: 0;
  transform: translateY(-50%);
}
.step-form-outer input[type='radio'] {
  display: none;
  /* opacity: 0;
  position: absolute; */
}
.step-form-outer input[type='radio']:checked + span {
  border: 2px solid #5723e8;
}
.step-form-outer input[type='radio']:checked + span::after {
  display: block;
  opacity: 1;
}
.footer-before::before {
  display: none;
}
.question-main {
  min-height: calc(100vh - 222px);
}

.total-cs-box {
  background: var(--color-primary-light);
}
.asses-img img {
  box-shadow: 0px 5px 64px 0px rgba(59, 48, 117, 0.4);
}
.cta-inner {
  background: linear-gradient(232.9deg, #1a1a1a 1.03%, #383838 99.97%);
  box-shadow: 0px 5px 64px 0px rgba(59, 48, 117, 0.4);
}
.accordion-item {
  background: #eaeaea;
  cursor: pointer;
}
.accordion-item .accordion-title::before {
  content: '';
  height: 24px;
  width: 24px;
  background: url(../public/images/accordian-icon.svg) center center no-repeat;
  position: absolute;
  right: 0px;
  top: 0;
}
.accordion-item .accordion-title {
  padding-right: 30px;
}
.accordion-content p {
  color: #1a1a1a;
}
.accordion-item.active .accordion-title::before {
  transform: rotate(180deg);
}
.faq-sec::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 80px;
  background: url(../public/images/bottom-layer.svg) 0 bottom no-repeat;
  left: 0;
  background-size: 100% auto;
  bottom: -80px;
}
.remove-after::after {
  display: none;
}
.sleepbot-logo {
  background: linear-gradient(232.9deg, #1a1a1a 1.03%, #383838 99.97%);
}

.news-box {
  background: #fff;
  box-shadow: 0px 5px 64px 0px rgba(59, 48, 117, 0.4);
}
.team-sec::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 50px;
  background: url(../public/images/top-layer.svg) 0 0 no-repeat;
  left: 0;
  background-size: 100% auto;
  top: -50px;
}
.team-sec::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 80px;
  background: url(../public/images/bottom-layer.svg) 0 bottom no-repeat;
  left: 0;
  background-size: 100% auto;
  bottom: -80px;
}
.team-block {
  width: calc(25% - 32px);
  margin-bottom: 32px;
}
.dark-text h4 {
  color: #000 !important;
}
.dark-text li {
  color: #000 !important;
}
.card-detail h4.mt-0 {
  margin-top: 0 !important;
}
.select-ans {
  background: url(../public/images/accordian-icon.svg) calc(100% - 30px) center
    no-repeat #e8e8e8;
  border: 2px solid transparent;
  cursor: pointer;
  appearance: none;
}
/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/

*,
::before,
::after {
  box-sizing: border-box;
  /* 1 */
  border-width: 0;
  /* 2 */
  border-style: solid;
  /* 2 */
  border-color: #e5e7eb;
  /* 2 */
}

::before,
::after {
  --tw-content: '';
}

/*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
5. Use the user's configured `sans` font-feature-settings by default.
6. Use the user's configured `sans` font-variation-settings by default.
*/

html {
  line-height: 1.5;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  /* 3 */
  tab-size: 4;
  /* 3 */
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  /* 4 */
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  /* 5 */
  font-variation-settings: normal;
  /* 6 */
}

/*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/

body {
  margin: 0;
  /* 1 */
  line-height: inherit;
  /* 2 */
}

/*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/

hr {
  height: 0;
  /* 1 */
  color: inherit;
  /* 2 */
  border-top-width: 1px;
  /* 3 */
}

/*
Add the correct text decoration in Chrome, Edge, and Safari.
*/

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

/*
Remove the default font size and weight for headings.
*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

/*
Reset links to optimize for opt-in styling instead of opt-out.
*/

a {
  color: inherit;
  text-decoration: inherit;
}

/*
Add the correct font weight in Edge and Safari.
*/

b,
strong {
  font-weight: bolder;
}

/*
1. Use the user's configured `mono` font family by default.
2. Correct the odd `em` font sizing in all browsers.
*/

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/*
Add the correct font size in all browsers.
*/

small {
  font-size: 80%;
}

/*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/

table {
  text-indent: 0;
  /* 1 */
  border-color: inherit;
  /* 2 */
  border-collapse: collapse;
  /* 3 */
}

/*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  -webkit-font-feature-settings: inherit;
  font-feature-settings: inherit;
  /* 1 */
  font-variation-settings: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  font-weight: inherit;
  /* 1 */
  line-height: inherit;
  /* 1 */
  color: inherit;
  /* 1 */
  margin: 0;
  /* 2 */
  padding: 0;
  /* 3 */
}

/*
Remove the inheritance of text transform in Edge and Firefox.
*/

button,
select {
  text-transform: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Remove default button styles.
*/

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  /* 1 */
  /* 2 */
  background-image: none;
  /* 2 */
}

/*
Use the modern Firefox focus style for all focusable elements.
*/

:-moz-focusring {
  outline: auto;
}

/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/

:-moz-ui-invalid {
  box-shadow: none;
}

/*
Add the correct vertical alignment in Chrome and Firefox.
*/

progress {
  vertical-align: baseline;
}

/*
Correct the cursor style of increment and decrement buttons in Safari.
*/

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/

[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/*
Remove the inner padding in Chrome and Safari on macOS.
*/

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/*
Add the correct display in Chrome and Safari.
*/

summary {
  display: list-item;
}

/*
Removes the default spacing and border for appropriate elements.
*/

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*
Reset default styling for dialogs.
*/

dialog {
  padding: 0;
}

/*
Prevent resizing textareas horizontally by default.
*/

textarea {
  resize: vertical;
}

/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  /* 1 */
  color: #9ca3af;
  /* 2 */
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  /* 1 */
  color: #9ca3af;
  /* 2 */
}

/*
Set the default cursor for buttons.
*/

button,
[role='button'] {
  cursor: pointer;
}

/*
Make sure disabled buttons don't get the pointer cursor.
*/

:disabled {
  cursor: no-drop;
  opacity: 0.7;
}

/*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  /* 1 */
  vertical-align: middle;
  /* 2 */
}

/*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/

img,
video {
  max-width: 100%;
  height: auto;
}

/* Make elements with the HTML hidden attribute stay hidden by default */

[hidden] {
  display: none;
}

*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

footer {
  background-color: #1a1a1a;
}

.cost-box {
  background: linear-gradient(232.9deg, #1a1a1a 1.03%, #383838 99.97%);
}

.menu {
  display: block;
  /* Add styling for menu container */
  padding: 16px;
  background-color: #333; /* Background color of the menu */
  flex-direction: column;
  border-radius: 20px;
}

.menu-link {
  display: block;
  color: white;
  margin-bottom: 1rem; /* Adjust this value to control the separation between links */
  border-radius: 1rem;
  text-decoration: none;
  color: #fff;
  padding: 10px;
}

/* CSS for responsive layout of the entire page */
.button-column-profile {
  align-items: center;
  padding-right: 0;
  padding-top: 10px;
}

@media (max-width: 768px) {
  .app-container {
    flex-flow: column;
  }
  .menu {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 10px 15px;
    
  }
  .button-column-profile {
    padding-bottom: 15px;
  }
  .menu-link svg{ 
    margin-bottom: 0;
    width: 20px;
    margin-right: 10px;
  }
  .menu-link {
    text-align: center;
    margin-bottom: 0;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 600px) {
  .menu {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 390px) {
  .menu {
    padding: 5px;
  }
}
@media (max-width: 370px) {
  .menu {
    display: block;
  }
}
.menu-link:hover {
  background-color: #555; /* Background color on hover */
  scale: 1.1;
}

/* Media query to stack elements horizontally on larger screens */
@media (min-width: 768px) {
  .button-column-profile {
    padding-right: 6%;
    flex-direction: row;
  }
}

/* button-generico para que sea media query */

/* lista de perfiles */
.profile-list {
  background-color: #373636; /* Cambia el color de fondo a uno que se ajuste a la estética deseada */
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
}

.profile-list-title {
  padding: 2rem;
  margin-top: 2rem;
  font-size: 18px;
  color: #ddd;
}
@media (max-width: 768px) {
  .profile-list-title {
    padding: 0;
    margin-bottom: 20px;
    margin-top: 0;
  }
}

.profile-list-items {
  list-style-type: none;
  padding: 0;
}

.profile-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profile-list-bullet {
  font-size: 20px;
  margin-right: 10px;
  color: #ddd;
}

.profile-list-name {
  font-size: 18px;
  font-weight: bold;
  color: #ddd;
}
.profile-list-empty {
  text-align: center;
  padding: 20px;
}

.profile-list-empty p {
  font-size: 18px;
  color: #ddd; /* Blanco más apagado */
  margin-bottom: 20px;
}

/*  */

.profile-list-button:hover {
  background-color: var(
    --color-primary-dark
  ); /* Color de fondo del botón al pasar el mouse */
}

.profile-list-button {
  display: block;
  text-align: center;
  margin: 20px auto;
  width: max-content;
}

/* Blog CSS*/
.page-container {
  background: #ffffff;
  padding-top: 22%;
}

.blog-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 2%;
  border: 1px solid #cccccc00;
  border-radius: 2%;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 1%;
  transition: all 0.3s;
}

.blog-container:hover {
  background: rgba(255, 255, 255, 1);
}

.blog-image {
  max-width: 50%;
  display: block;
  margin: 2% auto;
}

.blog-title {
  text-align: center;
  font-size: 110%;
  font-weight: 800;
  margin-bottom: 2%;
  color: var(--color-primary-dark);
}

.blog-text {
  text-align: left;
  font-size: 60%;
  line-height: 1.6;
  color: #333;
  max-width: 85%;
  margin: 0 auto;
  padding: 2%;
}

.blog-text p {
  margin-bottom: 2%;
}

.blog-text strong {
  font-weight: bold;
  color: #333;
}

/* Estilos para pantallas más pequeñas (ajusta los valores según sea necesario) */
@media (max-width: 1024px) {
  .buttons-profile {
    width: 150px;
    height: 40px;
  }
  /* Aumentar la especificidad */
  .buttons-profile .button-icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}

/* Media query for small screens */
@media (max-width: 600px) {
  button-generic {
    padding: 6px 10px; /* Adjust padding for smaller screens */
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}

/* Media query for medium screens */
@media (min-width: 601px) and (max-width: 1024px) {
  button-generic {
    padding: 6px 16px; /* Adjust padding for medium screens */
    font-size: 16px; /* Adjust font size for medium screens */
  }

  .page-container {
    padding-top: 10%; /* Adjust as needed */
  }

  .blog-container {
    max-width: 85%; /* Adjust as needed */
    padding: 1%; /* Adjust as needed */
  }

  .blog-image {
    max-width: 80%; /* Adjust as needed */
    margin: 1% auto; /* Adjust as needed */
  }

  .blog-title {
    font-size: 135%; /* Adjust as needed */
  }

  .blog-text {
    font-size: 70%; /* Adjust as needed */
    max-width: 90%; /* Adjust as needed */
    padding: 1%; /* Adjust as needed */
  }
}

@media only screen and (min-width: 1024px) {
  .solution-sec::before {
    content: '';
    height: 200%;
    width: 50%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: url(../public/images/orange-shadow.webp) right center no-repeat;
    background-size: auto 100%;
    pointer-events: none;
  }

  .solution-sec::after {
    content: '';
    height: 200%;
    width: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: url(../public/images/blue-shadow.webp) 0 center no-repeat;
    background-size: auto 100%;
    pointer-events: none;
  }

  .tab-sec-outer {
    margin: 50px 0 80px;
  }

  .tab-sec-outer::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 50px;
    background: url(../public/images/top-layer.svg) 0 0 no-repeat;
    left: 0;
    background-size: 100% auto;
    top: -50px;
  }
  .tab-sec-outer::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 80px;
    background: url(../public/images/bottom-layer.svg) 0 bottom no-repeat;
    left: 0;
    background-size: 100% auto;
    bottom: -80px;
  }

  .tab-content-inner li {
    width: calc(50% - 16px);
  }

  .product-list-box::before {
    background: #fff;
    border-radius: 3px;
    opacity: 0.20000000298023224;
    content: '';
    width: 6px;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
  .product-list-box.active::after {
    background: var(--color-primary-orange);
    border-radius: 3px;
    content: '';
    width: 6px;
    height: 50%;
    left: 0;
    top: 0;
    position: absolute;
  }
  .product-listing {
    width: 597px;
  }
  .product-list-box p {
    font-size: 16px;
  }
  .product-list-box {
    position: relative;
    padding-left: 30px;
  }

  .products-sec::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 125px;
    background: url(../public/images/product-bottom-bar.svg) 0 bottom no-repeat;
    left: 0;
    background-size: 100% auto;
    bottom: -125px;
  }
  .products-sec::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 50px;
    background: url(../public/images/product-top-bar.svg) 0 bottom no-repeat;
    left: 0;
    background-size: 100% auto;
    top: -50px;
  }
  .testimonial-sec::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 120px;
    background: url(../public/images/test-bottom-bar.svg) 0 bottom no-repeat;
    left: 0;
    background-size: 100% auto;
    bottom: -120px;
  }
  .testimonial-sec::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 50px;
    background: url(../public/images/testi-top-bar.svg) 0 top no-repeat;
    left: 0;
    background-size: 100% auto;
    top: -50px;
  }

  footer {
    position: relative;
    background: url(../public/images/footer-bg.webp) 0 0 no-repeat;
    background-size: cover;
  }

  .footer-right-box {
    width: calc(100% - 250px);
  }

  .flex-box p {
    font-size: 21px;
  }
  .card-detail li {
    font-size: 21px;
  }

  .card-detail p {
    font-size: 21px;
  }

  .sub-menu {
    background: rgba(255, 255, 255, 0.92);
    backdrop-filter: blur(5px);
  }
  .dd-link:hover .sub-menu {
    visibility: visible;
  }
  .sub-menu li a:hover {
    color: var(--color-primary-light);
    opacity: 1;
  }
  .navbar-inner {
    position: relative;
    background: rgba(26, 26, 26, 1);
    padding: 20px 0;
    border-bottom: ;
  }
  .navbar-inner::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0px;
    background: url(../public/images/inner-pages-hd-border.svg) 0 0 no-repeat;
    background-size: 100% auto;
    height: 2px;
    width: 100%;
  }
  .navbar-default {
    position: relative;
  }
  .white-header-logo {
    display: none;
  }
  .navbar-default .nav-list a {
    color: #1a1a1a;
  }
  .navbar-default .default-logo {
    display: none;
  }
  .navbar-default .white-header-logo {
    display: block;
  }

  .footer-top {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  .cost-box {
    width: calc(25% - 36px);
  }

  .login-left {
    width: 460px;
  }

  .language-menu {
    background: rgba(255, 255, 255, 0.92);
    backdrop-filter: blur(5px);
    width: auto;
    max-height: 200px;
    overflow-y: auto;
  }

  .language-menu li a {
    padding: 10px;
  }

  .dd-link:hover .language-menu {
    visibility: visible;
  }

  .language-menu li a:hover {
    color: var(--color-primary-light);
    opacity: 1;
  }

  .mi-enlace {
    position: relative;
  }

  .texto-oculto {
    display: none;
    color: red;
    margin-top: 10px; /* Espacio entre el enlace y el texto en rojo */
  }

  .mi-enlace:hover + .texto-oculto {
    display: block;
  }

  /* Resend page */

  .containerResend {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 60vh;
    text-align: center;
    margin-top: 4%;
  }

  .titleResend {
    font-size: 36px;
    font-weight: bold;
  }

  .subtitleResend {
    opacity: 0.7;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .messageResend {
    margin-top: 40px;
    font-weight: bold;
  }

  #resend-email-input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  .footer-col-was {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;
    align-items: center;
  }

  .title-was {
    grid-column: 1 / span 2;
    text-align: left;
  }

  .contact-info-was {
    display: flex;
    flex-direction: column;
  }

  /* blog */
  .page-container {
    padding-top: 3%; /* Adjust as needed */
  }

  .blog-container {
    max-width: 80%; /* Adjust as needed */
    padding: 0.5%; /* Adjust as needed */
    border: 1px solid #cccccc00;
  }

  /* .blog-container {
    max-width: 80%; 
    padding: 0.5%; 
    border: 1px solid #cccccc00;
    border-radius: 10px; 
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
    transition: all 0.3s;
  } */

  .blog-image {
    max-width: 60%; /* Adjust as needed */
    margin: 1% auto; /* Adjust as needed */
  }

  .blog-title {
    font-size: 190%; /* Adjust as needed */
  }

  .blog-text {
    font-size: 85%; /* Adjust as needed */
    max-width: 95%; /* Adjust as needed */
    padding: 1%; /* Adjust as needed */
  }
}

@media (min-width: 950px) and (max-width: 1301px) {
  .accesible-sec {
    padding: 0; /* Reduce el espacio inferior del contenedor */
  }
  .subtitle-accessible {
    max-width: 120%;
    margin-left: 20px;
  }
  .title-accessible {
    font-size: 2rem; /* Reduce el tamaño del título */
    max-width: 120%;
    margin-left: 20px;
  }

  .acess-image {
    position: relative; /* Cambia la posición de la imagen a relativa */
    top: -1px; /* Ajusta la posición vertical según tus necesidades */
    margin-left: 20px; /* Agrega un margen izquierdo a la imagen */
  }

  .accesible-inner {
    max-width: 600px; /* Aumenta el ancho máximo del contenedor de texto */
  }
}

@media (min-width: 1302px) and (max-width: 1490px) {
  .title-accessible {
    margin-left: -15%;
    max-width: 95%;
  }
  .subtitle-accessible {
    margin-left: -15%;
    max-width: 95%;
  }
}

.modalCalendly .close{
  position: absolute;
  right: -15px;
  top: -15px;
  margin: 0;
  left: auto;
}
.modalCalendly {
  position: fixed;
  inset: 0;
  z-index: 999;
  background: #000000a1;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 500px) {
  .footer-row {
    display: block;
  }
  .footer-col-was {
    margin-top: 25px;
    padding-left: 0;
  }
}

.basicForm.loading {
  opacity: .4;
  pointer-events: none;
}
.basicForm .select-container {
  position: relative;
}

.basicForm .select-container select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.basicForm .select-container svg {
  position: absolute;
  top: 50%;
  right: 2rem; 
  transform: translateY(-50%);
  font-size: 18px;
  color: #333;
  pointer-events: none; 
}